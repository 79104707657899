import { Box, Typography } from '@mui/material'

import { LayoutPage } from '#components'

const HomePage = () => (
  <LayoutPage>
    <Box
      sx={{
        backgroundColor: '#ece9e9',
        mt: '2rem',
        height: '15rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant="h2" component="h1" sx={{ color: '#1f1e1e', fontWeight: 500 }}>
        Home Page
      </Typography>
    </Box>
  </LayoutPage>
)

export default HomePage
