import { LoadingButton } from '@mui/lab'
import { useLocation, useNavigate } from 'react-router-dom'

import { Roles } from '#api/types'

import { RouteHome, RouteNotes, RouteSearchHouse, RouteUser } from '../config/menu'
import { type MenuByRole } from '../types'

type Props = {
  role?: Roles
  loggedIn: boolean
}

const MenuItems = ({ role, loggedIn }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  if (!role || !loggedIn) {
    return null
  }

  const menuByRole: MenuByRole = {
    [Roles.ADMIN]: [RouteSearchHouse, RouteHome, RouteNotes, RouteUser],
    [Roles.DIRECTOR]: [RouteHome],
    [Roles.EXPERT]: [RouteHome],
    [Roles.DOCUMENTARIAN]: [RouteHome],
    [Roles.SPECIALIST]: [RouteSearchHouse, RouteHome, RouteNotes]
  }

  const menuItemsByRole = menuByRole?.[role] ?? []

  const getActiveLink = (link: string) => {
    if (location.pathname === '/') {
      return 'text'
    }

    if (location.pathname.includes('apartment') && link === RouteHome.link) {
      return 'contained'
    }

    if (link.includes(location.pathname)) {
      return 'contained'
    }

    return 'text'
  }

  return (
    <>
      {menuItemsByRole.map(({ name, link }, idx) => (
        <LoadingButton
          variant={getActiveLink(link)}
          onClick={() => navigate(link)}
          key={`${idx}-${name}`}
          sx={{ mr: 0.5 }}
        >
          {name}
        </LoadingButton>
      ))}
    </>
  )
}

export { MenuItems }
