import { RemarksByApartment } from '#api/types'
import { Table } from '#components'

import { useCell, useData } from './hooks'
import { RemarkByHome } from './types'

type Props = {
  remarks: RemarksByApartment[]
}

const InfoTable = ({ remarks }: Props) => {
  const headCells = useCell()
  const dataSource: RemarkByHome[] = useData({ remarks })

  return (
    <Table
      headCells={headCells}
      isSelect={false}
      defaultOrder="id"
      tableName="Найденные замечания по дому"
      propKey="id"
      perPage={10}
      hasDetailPagination={true}
      tablePage={1}
      dataSource={dataSource}
    />
  )
}

export { InfoTable }
