import { useContextSafe } from '#hooks/use-context-safe'

import { SelectedContext } from '../../context'
import { InfoTable, SelectRemarks } from './components'
import { useData } from './hooks'

const HouseSearchResult = () => {
  const { houseId, typeId } = useContextSafe(SelectedContext)
  const { notesByTypeId, remarksByTypeAndHouse } = useData()

  const hasInfoTable = !houseId || !typeId

  return (
    <>
      {typeId && <SelectRemarks remarks={notesByTypeId} />}

      {!remarksByTypeAndHouse.length
        ? 'Замечаний не найдено'
        : !hasInfoTable && <InfoTable remarks={remarksByTypeAndHouse} />}
    </>
  )
}

export { HouseSearchResult }
