import { LogoDev, Person, Sync } from '@mui/icons-material'
import { Box, Tab, Tabs } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { LayoutPage, TabPanel } from '#components'
import { usePreloadingData } from '#hooks/use-preloading-data'
import { useAppSelector } from '#store'

import { setIsPreloadingData } from '../../redux/features/userSlice'
import { ProfileInfo, ProfileLogs, ProfileSyncTelegram } from './components'

const ProfilePage = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const dispatch = useDispatch()
  const isPreloadingData = useAppSelector((state) => state.userState.isPreloadingData)
  const { fetchAllCollection } = usePreloadingData()

  useEffect(() => {
    if (isPreloadingData) {
      return
    }

    fetchAllCollection()
      .then((data) => {
        console.log(data)
        console.log('Вы успешно вошли в систему, данные получены')
        dispatch(setIsPreloadingData(true))
      })
      .catch((err) => {
        console.log(err)
        toast.error('При формировании данных возникла ошибка')
        dispatch(setIsPreloadingData(false))
      })
  }, [])

  return (
    <LayoutPage title="Личный кабинет">
      <Box sx={{ mt: 2 }}>
        <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
          <Tab icon={<Person />} label="Личные данные" />
          <Tab icon={<Sync />} label="Синхронизации" />
          <Tab icon={<LogoDev />} label="Логи" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ProfileInfo />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProfileSyncTelegram />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProfileLogs />
        </TabPanel>
      </Box>
    </LayoutPage>
  )
}

export default ProfilePage
