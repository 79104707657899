import { Check, Clear, List, Remove } from '@mui/icons-material'
import { Button, ButtonGroup, MenuItem, Stack } from '@mui/material'
import { IconButton } from '@mui/material/'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ReactElement } from 'react'

import { Note } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'

import { SelectedContext } from '../../../../context'

type Props = {
  remarks: Note[]
}

type StatusProps = {
  color: 'primary' | 'success' | 'error' | 'inherit' | 'secondary' | 'info' | 'warning'
  value: number
  icon: ReactElement
  label: string
}

const Status: { [key: string]: StatusProps } = {
  All: {
    value: -1,
    color: 'primary',
    label: 'Все',
    icon: <List />
  },
  Active: {
    value: 1,
    color: 'success',
    label: 'Выполнено',
    icon: <Check />
  },
  NoActive: {
    value: 0,
    color: 'error',
    label: 'В работе',
    icon: <Remove />
  }
}

const SelectRemarks = ({ remarks }: Props) => {
  const { setRemarkId, remarkId, active, setActive } = useContextSafe(SelectedContext)

  const handleChange = (event: SelectChangeEvent) => {
    setRemarkId(event.target.value as unknown as number)
  }

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <span>Выберите наименование замечания:</span>
        <Select
          value={remarkId as unknown as string}
          size="small"
          onChange={handleChange}
          displayEmpty
          sx={{ width: '100%%', minWidth: 250 }}
        >
          {remarks.map((remark) => (
            <MenuItem key={remark.id} value={remark.id}>
              {remark.name}
            </MenuItem>
          ))}
        </Select>
        <IconButton onClick={() => setRemarkId(null)}>
          <Clear />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', mt: 2, mb: 2 }}>
        <span>Готовность:</span>
        <ButtonGroup sx={{ ml: 2 }} variant="outlined">
          {Object.values(Status).map((status, idx) => (
            <Button
              variant={active === status.value ? 'contained' : 'outlined'}
              onClick={() => setActive(status.value)}
              color={status.color}
              key={idx}
            >
              {status.label}
              {status.icon}
            </Button>
          ))}
        </ButtonGroup>
      </Stack>
    </>
  )
}

export { SelectRemarks }
