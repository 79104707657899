import { createApi } from '@reduxjs/toolkit/query/react'

import { LoginInput } from '../../pages/login/types/shema.type'
import { RegisterInput } from '../../pages/register.page'
import { setUser } from '../features/userSlice'
import { withBaseQuery } from '../helpers/prepare-headers'
import { IGenericResponse, IUser, Roles } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: withBaseQuery(`${BASE_URL}/api/auth/`),
  endpoints: (builder) => ({
    registerUser: builder.mutation<IGenericResponse, RegisterInput>({
      query(data) {
        return {
          url: 'register',
          method: 'POST',
          body: data
        }
      }
    }),
    linkingTelegram: builder.mutation<unknown, null>({
      query() {
        return {
          url: 'linking/telegram',
          credentials: 'include'
        }
      }
    }),
    resetTelegramId: builder.mutation<boolean, unknown>({
      query() {
        return {
          url: 'reset/telegram',
          credentials: 'include'
        }
      }
    }),
    refresh: builder.query<{ access_token: string; user: IUser }, null>({
      query() {
        return {
          url: 'refresh',
          method: 'POST',
          credentials: 'include'
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localStorage.setItem('token', data.access_token)

          dispatch(setUser(data.user))
          // await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      }
    }),
    profile: builder.query<IUser, null>({
      query() {
        return {
          url: 'user-profile',
          credentials: 'include'
        }
      },
      transformResponse: (result: IUser) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data as IUser))
        } catch (err: any) {
          const status = err?.error?.originalStatus
          if (status === 500) {
            window.location.pathname = '/login'
          }
        }
      }
    }),
    loginUser: builder.mutation<{ access_token: string; user: IUser }, LoginInput>({
      query(data) {
        return {
          url: 'login',
          method: 'POST',
          body: data,
          credentials: 'include'
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localStorage.setItem('token', data.access_token)

          dispatch(setUser({ ...data.user, role: Roles.ADMIN }))
          // await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      }
    }),
    verifyEmail: builder.mutation<IGenericResponse, { verificationCode: string }>({
      query({ verificationCode }) {
        return {
          url: `verifyemail/${verificationCode}`,
          method: 'GET'
        }
      }
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'logout',
          method: 'POST',
          credentials: 'include'
        }
      }
    })
  })
})

export const {
  useResetTelegramIdMutation,
  useLinkingTelegramMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation
} = authApi
