import { RemarksByApartment } from '#api/types'
import { getFullName } from '#utils'

type Props = {
  remarks: RemarksByApartment[]
}

const RemarkCell = ({ remarks }: Props) => (
  // @ts-ignore
  <table border={1} cellpadding="10px" width="100%">
    <tbody>
      {remarks.map((remark) => (
        <tr key={remark.id}>
          <td width="30%">{remark.remark?.name}</td>
          <td width="25%">{remark.description}</td>
          <td width="15%">{getFullName(remark?.user)}</td>
          <td width="15%">{new Date(remark.created_at).toLocaleDateString()}</td>
          <td width="15%">{remark.accepted ? 'Готово' : 'В работе'}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export { RemarkCell }
