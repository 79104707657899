import { useMemo } from 'react'

import { useContextSafe } from '#hooks/use-context-safe'

import { SelectedContext } from '../../../context'

const useData = () => {
  const { houseId, typeId, remarkId, remarks, notesByType, active } = useContextSafe(SelectedContext)

  const remarksByTypeAndHouse = useMemo(() => {
    let filterRemarks = remarks.filter((remark) => {
      const { apartment, apartments_acceptance_types_id } = remark

      return apartments_acceptance_types_id === typeId && apartment?.house_id === houseId
    })

    if (remarkId) {
      filterRemarks = filterRemarks.filter((remark) => remark.remark_id === remarkId)
    }

    if (active === 0) {
      return filterRemarks.filter((remark) => !remark.accepted)
    }

    if (active === 1) {
      return filterRemarks.filter((remark) => remark.accepted)
    }

    return filterRemarks
  }, [houseId, typeId, remarks, remarkId, active])

  const notesByTypeId = useMemo(() => {
    const notes = notesByType.find((note) => note.id === typeId)

    return notes?.remarks ?? []
  }, [typeId, notesByType])

  return {
    notesByTypeId,
    remarksByTypeAndHouse
  }
}

export { useData }
