import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import { Apartment, ApartmentsAcceptanceType, House } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { RemarksByType } from '#types/remark'
import { buildRelevantRemark, buildRemarksByType, getColorRow, getNameByType } from '#utils'

import { HeadCell } from '../../../components/table/type'
import { ColorRow, ColorRowByExtent, ExtentApartment } from '../../../config/constants'
import { CountRemark } from '../components'

type Props = {
  house: House | null
  types: ApartmentsAcceptanceType[] | null
  groupByApartment: RemarksByType
}

const useDataTable = ({ types, house, groupByApartment }: Props) => {
  const { isDirectorRight, isSpecialistRight, isAdminRight, isExpertRight } = useRightCurrentUser()

  const isVisibleLink = isSpecialistRight || isAdminRight
  const isVisibleCountRemark = isVisibleLink || isExpertRight

  if (types === null || house === null) {
    return {
      headCells: [],
      dataSource: []
    }
  }

  const directorWidth = isDirectorRight
    ? {
        width: '200px'
      }
    : {
        'min-width': '200px'
      }

  const headCells: HeadCell<Apartment>[] = [
    {
      id: 'number',
      numeric: true,
      disablePadding: false,
      label: 'Номер квартиры',
      style: {
        whiteSpace: 'nowrap',
        wordWrap: 'break-word',
        ...directorWidth
      },
      render: (id: string, apartment) => {
        const isMaster = apartment.extent !== ExtentApartment.Default && apartment.document !== null
        const count = (groupByApartment?.[apartment.id] ?? []).filter(({ user_verify_id }) => !user_verify_id).length
        const hasLink = count > 0 || isMaster || isVisibleLink
        const nameByType = getNameByType(apartment.type)

        return (
          <>
            <Grid sx={{ pr: 2 }} alignItems="center" justifyContent="flex-end" container>
              {hasLink ? (
                <Link to={`/apartment/${house.id}/${apartment.id}`}>
                  {nameByType} №{id}
                </Link>
              ) : (
                `${nameByType} №${id}`
              )}
              {isVisibleCountRemark && <CountRemark groupByApartment={groupByApartment} id={apartment.id} />}
            </Grid>
            {count > 0 && <small style={{ paddingRight: '20px' }}>Замечаний: {count}</small>}
          </>
        )
      }
    }
  ]

  if (!isDirectorRight) {
    types.forEach(({ name, slug, id }) => {
      headCells.push({
        id: name,
        numeric: true,
        disablePadding: false,
        label: slug,
        align: 'center',
        style: {
          whiteSpace: 'nowrap',
          wordWrap: 'break-word'
        },
        render: (val, apartment) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {val}
            {isVisibleCountRemark && <CountRemark groupByApartment={groupByApartment} id={apartment.id} typeId={id} />}
          </div>
        )
      })
    })
  } else {
    headCells.push({
      id: 'lastDate',
      numeric: true,
      disablePadding: false,
      label: 'Последнее замечание'
    })
  }

  const getDataSourceByNoDirector = (item: Apartment) => {
    const apartmentWorks = groupByApartment?.[item.id] ?? []
    const remarksByType = buildRemarksByType(apartmentWorks)

    const stateApartments = types.reduce((acc, { name, id }) => {
      const remarks = remarksByType[id] ?? []
      const relevantRemark = buildRelevantRemark(remarks)
      const colorCell = relevantRemark
        ? getColorRow(relevantRemark, ColorRow.WHITE)
        : { backgroundColor: ColorRow.WHITE }

      return {
        ...acc,
        [`${name}.options.cell-style`]: colorCell,
        [name]: relevantRemark?.endTime && new Date(relevantRemark?.endTime).toLocaleDateString()
      }
    }, {})

    return {
      id: item.id,
      number: item.number,
      extent: item.extent,
      document: item.document,
      type: item.type ?? 1,
      countRemark: apartmentWorks.length,
      [`number.options.cell-style`]: { backgroundColor: ColorRowByExtent[item.extent] ?? ColorRow.WHITE },
      ...stateApartments
    }
  }

  const getDataSourceByDirector = (item: Apartment) => {
    const apartmentWorks = groupByApartment?.[item.id] ?? []
    const relevantRemark = buildRelevantRemark(apartmentWorks)
    const colorCell = relevantRemark ? getColorRow(relevantRemark, ColorRow.WHITE) : { backgroundColor: ColorRow.WHITE }

    return {
      [`lastDate.options.cell-style`]: colorCell,
      extent: item.extent,
      id: item.id,
      number: item.number,
      document: item.document,
      lastDate: relevantRemark?.endTime && new Date(relevantRemark?.endTime).toLocaleDateString(),
      countRemark: apartmentWorks.length,
      type: item.type ?? 1,
      [`number.options.cell-style`]: { backgroundColor: ColorRowByExtent[item.extent] ?? ColorRow.WHITE },
      relevantRemark
    }
  }

  const dataSource = house.apartments.map(!isDirectorRight ? getDataSourceByNoDirector : getDataSourceByDirector)

  return {
    headCells,
    dataSource
  }
}

// @ts-ignore
export { useDataTable }
