import { createContext, ReactElement, useEffect, useMemo, useState } from 'react'

import { useAcceptancesMutation, useNotesByTypeMutation } from '#api/note-api'
import { NotesByType, RemarksByApartment } from '#api/types'
import { useAppSelector } from '#store'

type SelectedContextState = {
  houseId: null | number
  setHouseId: (houseId: number) => void
  typeId: null | number
  setTypeId: (typeId: number) => void
  remarkId: null | number
  setRemarkId: (remarkId: number | null) => void
  active: number
  setActive: (active: number) => void
  notesByType: NotesByType[]
  remarks: RemarksByApartment[]
}

type Props = {
  children: ReactElement
}

const SelectedContext = createContext<SelectedContextState | null>(null)

const SelectedContextProvider = ({ children }: Props) => {
  const [getNotesByType] = useNotesByTypeMutation()
  const [getRemarksAllHouses] = useAcceptancesMutation()
  const [houseId, setHouseId] = useState<number | null>(null)
  const [typeId, setTypeId] = useState<number | null>(null)
  const [remarkId, setRemarkId] = useState<number | null>(null)
  const [active, setActive] = useState<number>(0)
  const notesByType = useAppSelector((state) => state.noteState.notesByType) ?? []
  const remarks = useAppSelector((state) => state.noteState.remarks)

  useEffect(() => {
    getNotesByType(null)
    getRemarksAllHouses(null)
  }, [])

  const value = useMemo(
    () => ({ houseId, setHouseId, typeId, setTypeId, remarkId, setRemarkId, notesByType, remarks, active, setActive }),
    [houseId, typeId, remarkId, notesByType, remarks, active]
  )

  return <SelectedContext.Provider value={value}>{children}</SelectedContext.Provider>
}

export { SelectedContextProvider, SelectedContext }
